import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ism } from '@madss/json-ism';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentVariables } from '../models/environment-variables';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentVariablesService {

  /**
   * Constructor
   *
   * @param http HttpClient to use for making requests of the service
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Get the environment variables from the login service.
   */
  public getEnvironmentVars(): Observable<EnvironmentVariables> {
    return this.http.get<EnvironmentVariables>('external/ENV').pipe(
      map(environment => ({
        ...environment,

        // The "Ism" object provided by the server might actually be
        // IsmMarkedData and might also not be normalized. While strictly it
        // might be more correct to change the type in UserManagementEnvironment
        // to "Ism | IsmMarkedData", this spreads handling this situation to all
        // of the code that needs to get the environment, when it could be
        // handled in a single central spot (here).

        classificationIsm: Ism.toIsm(environment.classificationIsm)
      }))
    );
  }
}
