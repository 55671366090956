import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AppSettingsService } from '../services/app-settings.service';

@Injectable()
export class MaintenanceRedirect  {

  appSettings = this.appSettingsService.getAppSettings();

  enableMaintenanceMode = this.appSettings.enableMaintenanceMode;

  readonly redirect = 'ext/welcome';

  constructor(
    private appSettingsService: AppSettingsService,
    private router: Router) {}

  canActivate(): boolean | UrlTree {
    return this.enableMaintenanceMode ?
      this.router.parseUrl('welcome') : true;
  }
}
