<ng-container *ngIf="message">
  <alert
    *ngIf="message.type === 'success'"
    type="success"
    [dismissible]="!message.preventDismissal && (!message.messageButtons || message.messageButtons.length === 0)"
    (onClosed)="onDismissAlert()">
    <ng-container *ngIf="message; then alertContent"></ng-container>
  </alert>

  <alert
    *ngIf="message.type === 'error'"
    type="danger"
    [dismissible]="!message.preventDismissal && (!message.messageButtons || message.messageButtons.length === 0)"
    (onClosed)="onDismissAlert()">
    <ng-container *ngIf="message; then alertContent"></ng-container>
  </alert>

  <alert
    *ngIf="message.type === 'info'"
    type="info"
    [dismissible]="!message.preventDismissal && (!message.messageButtons || message.messageButtons.length === 0)"
    (onClosed)="onDismissAlert()">
    <ng-container *ngIf="message; then alertContent"></ng-container>
  </alert>

  <alert
    *ngIf="message.type === 'warning'"
    type="warning"
    [dismissible]="!message.preventDismissal && (!message.messageButtons || message.messageButtons.length === 0)"
    (onClosed)="onDismissAlert()">
    <ng-container *ngIf="message; then alertContent"></ng-container>
  </alert>
</ng-container>

<ng-template #alertContent>
  <div class="d-flex">
    <ng-container
      *ngIf="message.secondaryInstruction; else noSecondaryInstruction">
      <span [id]="'alertMessageMainInstruction-' + message.id" class="fw-bold me-1">
        <app-message-markdown
          [message]="message.mainInstruction"
          [openUrlInSameWindow]="message.openMarkdownLinksInSameWindow"
        ></app-message-markdown>
      </span>
      <span [id]="'alertMessageSecondaryInstruction-' + message.id">
        <app-message-markdown
          [message]="message.secondaryInstruction"
          [openUrlInSameWindow]="message.openMarkdownLinksInSameWindow"
        ></app-message-markdown>
      </span>
    </ng-container>

    <ng-template #noSecondaryInstruction>
      <span [id]="'alertMessageMainInstruction-' + message.id">
        <app-message-markdown
        [message]="message.mainInstruction"
        [openUrlInSameWindow]="message.openMarkdownLinksInSameWindow"
      ></app-message-markdown>
      </span>
    </ng-template>

    <span
      [id]="'alertMessageButtons-' + message.id"
      class="ms-auto">
      <button
        *ngFor="let button of message.messageButtons"
        [ngClass]="{
          'btn-outline-primary': button.isPrimary,
          'btn-outline-secondary': !button.isPrimary
        }"
        class="btn btn-sm"
        (click)="invokeButtonAction(button.action)"
      >
        {{button.label}}
      </button>
    </span>
  </div>
</ng-template>
