<div class="container-fluid outer-div d-flex flex-column justify-content-center">
  <div class="row justify-content-center mb-3 mt-1">
    <div class="col-11">
      <img class="w-100" src="assets/images/madss-title.png" />
    </div>
  </div>
  <div class="row justify-content-center flex-grow-1 h-100 mt-50">
    <div class="col-xs-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 h-100">
      <div
        [ngClass]="
          dodNoticeAcknowledged
            ? 'row justify-content-center'
            : 'row justify-content-center h-100'
        "
      >
        <div class="col-xs-11 col-sm-10 col-md-9 col-lg-8 col-xl-7 mb-5">
          <div class="form h-100">
            <div *ngIf="!dodNoticeAcknowledged" class="p-3 h-100">
              <div class="justify-content-center d-flex flex-column h-100">
                <div>
                  <h4 class="text-center pb-2">DoD Consent Notice</h4>
                </div>
                <div>
                  <div class="text-center col-11 font-weight-bold pb-1">
                    Entry into this application is considered consent to the
                    following DoD notice:
                  </div>
                </div>
                <div class="justify-content-center overflow-auto flex-grow-1">
                  <div id="ToSPPATextBox">
                    <p class="m-0 p-0">
                      You are accessing a U.S. Government (USG) Information
                      System (IS) that is provided for USG-authorized use
                      only.
                    </p>
                    <p class="m-0 p-0">
                      By using this IS (which includes any device attached to
                      this IS), you consent to the following conditions:
                    </p>
                    <p class="m-0 p-0">
                      The USG routinely intercepts and monitors communications
                      on this IS for purposes including, but not limited to,
                      penetration testing, COMSEC monitoring, network
                      operations and defense, personnel misconduct (PM), law
                      enforcement (LE), and counterintelligence (CI)
                      investigations.
                    </p>
                    <p class="m-0 p-0">
                      At any time, the USG may inspect and seize data stored
                      on this IS.
                    </p>
                    <p class="m-0 p-0">
                      Communications using, or data stored on, this IS are not
                      private, are subject to routine monitoring,
                      interception, and search, and may be disclosed or used
                      for any USG-authorized purpose.
                    </p>
                    <p class="m-0 p-0">
                      This IS includes security measures (e.g., authentication
                      and access controls) to protect USG interests—not for
                      your personal benefit or privacy.
                    </p>
                    <p class="m-0 p-0">
                      Notwithstanding the above, using this IS does not
                      constitute consent to PM, LE, or CI investigative
                      searching or monitoring of the content of privileged
                      communications, or work product, related to personal
                      representation or services by attorneys,
                      psychotherapists, or clergy, and their assistants. Such
                      communications and work product are private and
                      confidential. See User Agreement for details.
                    </p>
                  </div>
                </div>
                <div class="justify-content-center">
                  <button
                    id="TOSButton"
                    type="submit"
                    class="btn btn-primary btn-large btn-block mt-2 w-100"
                    autofocus
                    (click)="acknowledgeDodNotice()"
                  >
                    I agree
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="dodNoticeAcknowledged" class="p-3">
              <div>
                <app-message-alert
                  [message]="this.message">
                </app-message-alert>
              </div>
              <div class="text-center h3 mb-2">
                <span>Welcome to MADSS</span>
              </div>
              <form
                [formGroup]="pkiForm"
                (ngSubmit)="onPkiSubmit()"
                class="login-form h-100">
                <div>
                  <button id="submitPki"
                          class="btn btn-success btn-large btn-block mt-2 w-100" type="submit"
                          [attr.disabled] = "loggingInPki ? 'disabled' : null">
                    <span *ngIf="!loggingInPki">CAC/PKI Login</span>
                    <span *ngIf="loggingInPki" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                    <span *ngIf="loggingInPki" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                    <span *ngIf="loggingInPki" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                  </button>
                </div>
                <div
                  *ngIf="hasPkiError"
                  class="bg-dark">
                  <span class="text-danger m-2">{{ pkiLoginFeedback }}</span>
                </div>
              </form>
              <div *ngIf="!disableBasicLogin" class="text-center h5 mt-3">
                <span>OR</span>
              </div>
              <form
                *ngIf="!disableBasicLogin"
                [formGroup]="loginForm"
                (ngSubmit)="onSubmit()"
                class="login-form h-100"
              >
                <label>Username</label>
                <input
                  #usernameField
                  type="text"
                  class="form-control mb-2"
                  formControlName="username"
                  (input)="clearLoginFeedback()"
                  [ngClass]="{ 'is-invalid': !valid }"
                />
                <label>Password</label>
                <input
                  #passwordField
                  type="password"
                  class="form-control mb-2"
                  formControlName="password"
                  (input)="clearLoginFeedback()"
                  [ngClass]="{ 'is-invalid': !valid }"
                />
                <div>
                  <button id="submitBasic"
                          class="btn btn-primary btn-large btn-block mt-2 w-100" type="submit"
                          [attr.disabled] = "loggingIn ? 'disabled' : null">
                    <span *ngIf="!loggingIn">Login</span>
                    <span *ngIf="loggingIn" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                    <span *ngIf="loggingIn" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                    <span *ngIf="loggingIn" class="spinner-grow spinner-grow-sm"
                          role="status" aria-hidden="true"></span>
                  </button>
                </div>
                <div *ngIf="hasError" class="bg-dark">
                  <span class="text-danger m-2">{{ loginFeedback }}</span>
                </div>
              </form>
              <div class="d-flex mt-2 justify-content-end">
                <button class="btn btn-secondary btn-sm" (click)="showHelpDeskInfo()">Contact the help desk</button>
              </div>
              <span class="label label-default">
                <span class="madss-data" id="navbarEnvironmentVersion">Version: {{packageSettings ? packageSettings.version || '&lt;none&gt;' : '&lt;none&gt;'}}</span>
                <span class="madss-data" id="navbarEnvironmentInstance">Instance: {{packageSettings ? packageSettings.instance || '&lt;none&gt;' : '&lt;none&gt;'}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Only display for development environment -->
    <div *ngIf="dodNoticeAcknowledged && packageSettings?.instance === 'enterprise'">
      <img class="env-img" src="assets/images/Unclass Tag_shadow.png" />
    </div>
    <div class="JCC2-img">
      <img class="w-100" src="assets/images/JCC2_White.png" />
    </div>
  </div>
</div>
