<div class="modal-content">
  <div class="modal-header d-flex">
    <div class="main-instruction modal-title">
      Contact the MADSS help desk
    </div>

    <button
      type="button"
      class="btn-close btn-close-white ms-auto"
      (click)="onCloseClicked()">
    </button>
  </div>

  <div class="modal-body">
    <ng-container *ngIf="appSettings$ | async as appSettings; else noAppSettings">
      <table class="table table-striped table-borderless mt-1">
        <tr *ngFor="let contactMethod of appSettings.helpDeskContactMethods">
          <td class="p-2">{{contactMethod.type}}</td>
          <td class="p-2">{{contactMethod.value}}</td>
        </tr>
      </table>
    </ng-container>

    <ng-template #noAppSettings>
      We're sorry, but MADSS could not load the contact info for the help desk.
      Please try again later.
    </ng-template>
  </div>

  <div class="modal-footer">
    <div class="btn-group d-flex w-100" role="group">
      <button type="button" class="btn btn-primary w-100" (click)="onCloseClicked()">
        Close
      </button>
    </div>
  </div>
</div>
