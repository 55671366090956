import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Login } from 'src/app/models/login';
import { LoginService } from 'src/app/services/login.service';
import { EnvironmentVariablesService } from '../../services/environment-variables.service';
import { Subscription } from 'rxjs';
import { EnvironmentVariables } from '../../models/environment-variables';
import { ClassificationOption, normalizeClassificationOption } from '@madss/json-ism';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy, OnInit {
  topLevelBannerText: string;

  envVar: EnvironmentVariables;

  classificationOption: ClassificationOption;
  hasSciControls: boolean;

  subscriptions: Subscription[] = [];

  loginForm: FormGroup;

  readonly ClassificationOption = ClassificationOption;

  /**
   * Constructor - sets up page view.
   *
   * @param formBuilder Genric form builder for the login form (Username and Password)
   *
   * @param loginService Service for handling login requests using UN/PW and PKI
   *
   * @param environmentService Service for pulling environment variables.
   */
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private environmentService: EnvironmentVariablesService) {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
   }

  onSubmit() {
    const login: Login = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    };

    this.loginService.loginUser(login)
      .subscribe(() => {
        window.location.href = window.origin;
      });
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach(sub => sub.unsubscribe());
    }
  }

  ngOnInit() {
    this.subscriptions.push(
      this.environmentService.getEnvironmentVars().subscribe(env => {
        if (env) {
          this.envVar = env;
          this.topLevelBannerText = env.classification;

          const classificationOption = env.classificationIsm?.classification;

          this.classificationOption =
            classificationOption ? normalizeClassificationOption(classificationOption) :
            classificationOption as ClassificationOption; // if falsy doesn't matter if synonym

          this.hasSciControls = !!env.classificationIsm?.sciControls?.length;
        }
      })
    );
  }
}
