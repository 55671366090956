import { Component } from '@angular/core';
import { clearLocalStorage } from './helpers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app-login';

  constructor() {
    clearLocalStorage();
  }
}
