<div [hidden]="enableMaintenanceMode" class="welcome_image h-100">
  <div class="text-center">
    <div *ngIf="message" class="">
      <alert
        type="success"
        [dismissible]="true"
        (onClosed)="onDismissAlert()"
        >{{ message }}</alert
      >
    </div>
  </div>
  <app-login class="h-100"></app-login>
</div>
<div [hidden]="!enableMaintenanceMode" class="construction-container" style="background-image: url(assets/images/madss-construction.png)">
  <div class="text-center bg-dark">
    <div
      *ngIf="maintenanceModeMessage"
      class="justify-content-center overflow-auto flex-grow-1"
    >
      <p class="m-0 p-0">
        {{ maintenanceModeMessage }}
      </p>
    </div>
  </div>
</div>
