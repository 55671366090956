/**
 * Clears all data in the local storage.
 */
export function clearLocalStorage(): void {
  try {
    localStorage.clear();
  }
  catch (error) {
    console.error('Failed to clear localstorage', error);
  }
}
