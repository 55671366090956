<div class="d-flex flex-column h-100">
  <div>
    <div
      class="classification-banner"
      [ngClass]="{
        'unclassified':
          classificationOption === ClassificationOption.Unclassified &&
          !hasSciControls,
        'cui':
          classificationOption === ClassificationOption.Cui &&
          !hasSciControls,
        'restricted':
          classificationOption === ClassificationOption.Restricted &&
          !hasSciControls,
        'confidential':
          classificationOption === ClassificationOption.Confidential &&
          !hasSciControls,
        'secret':
          classificationOption === ClassificationOption.Secret &&
          !hasSciControls,
        'topSecret':
          classificationOption === ClassificationOption.TopSecret &&
          !hasSciControls,
        'hasSci': hasSciControls
      }">
        {{topLevelBannerText}}
    </div>
  </div>
  <div>
    <div class="col-12 p-0 m-0">
    </div>
  </div>
  <div class="flex-grow-1">
    <div class="col-12 p-0 m-0 h-100">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
