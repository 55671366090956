import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { BsModalService } from 'ngx-bootstrap/modal';
import { take } from 'rxjs/operators';
import { AppSettings } from 'src/app/models/app-settings';
import { EnvironmentVariables } from 'src/app/models/environment-variables';
import { EnvironmentVariablesService } from 'src/app/services/environment-variables.service';
import { AlertMessage } from 'src/app/shared/models/alert-message';
import { environment } from 'src/environments/environment';
import { Login } from './../../models/login';
import { AppSettingsService } from './../../services/app-settings.service';
import { LoginService } from './../../services/login.service';
import { HelpDeskModalComponent } from './../help-desk-modal/help-desk-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public loginFeedback: string;
  public pkiLoginFeedback: string;

  valid = true;
  dodNoticeAcknowledged = false;
  hasError = false;
  loggingIn = false;
  hasPkiError = false;
  loggingInPki = false;
  message: AlertMessage = undefined;

  @ViewChild('usernameField')
  usernameField: ElementRef;

  packageSettings: EnvironmentVariables;
  appSettings: AppSettings;
  disableBasicLogin?: boolean;
  loginForm: FormGroup;
  pkiForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private environmentService: EnvironmentVariablesService,
    private appSettingsService: AppSettingsService,
    private modalService: BsModalService) {

    this.appSettings = this.appSettingsService.getAppSettings();
    this.disableBasicLogin = this.appSettings.disableBasicLogin;

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.pkiForm = this.formBuilder.group({});
  }

  ngOnInit() {
      this.environmentService.getEnvironmentVars()
      .pipe(
        take(1)
      )
      .subscribe(env => {
        if (env) {
          this.packageSettings = env;
        }
      });

      if (this.appSettings === undefined) {
        console.log('Appsettings could not be loaded');
      }
      else {
        if (!!this.appSettings.persistentNotification?.message &&
            this.checkNotificationDate(
              this.appSettings.persistentNotification.startDate,
              this.appSettings.persistentNotification.endDate)) {

            this.message = {
              mainInstruction: this.appSettings.persistentNotification.message,
              type: this.appSettings.persistentNotification.type,
              preventDismissal: true
            };

        }
      }

  }

  /**
   * Handles the click from the login button.
   * Gets the username and password from the
   * text controls and calls the login service.
   */
  onSubmit() {
    const login: Login = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password
    };
    this.loggingIn = true;
    this.loginService.loginUser(login)
      .subscribe(() => {
        this.loggingIn = false;
        this.redirectUsers();
      },
      () => {
        this.setLoginFeedback('Username or password could not be found or is incorrect');
      });
  }

  /**
   * Handles the click from the CAC/PKI button.
   * Calls the login service to prompt for the
   * client certificate.
   */
  onPkiSubmit() {
    this.loggingInPki = true;
    this.loginService.pkiLogin()
      .subscribe(() => {
        this.loggingInPki = false;
        this.redirectUsers();
      },
      () => {
        this.setPkiLoginFeedback('Certificate not found or invalid');
      });
  }

  /**
   * Handles the click from the agreement button
   * indicating the user has agreed to the conditions
   * of accessing the MADSS II application.
   */
  acknowledgeDodNotice(): void {
    this.dodNoticeAcknowledged = true;
    // Use of timeout function allows for current thread (Submit action)
    // to complete before attempting to shift focus.
    setTimeout(() => {
      this.usernameField.nativeElement.focus();
    }, 0);
  }

  /**
   * Sets feedback from a PKI login attempt.
   */
  setPkiLoginFeedback(feedback: string): void {
    this.pkiLoginFeedback = feedback;
    this.hasPkiError = true;
    this.loggingInPki = false;
  }

  /**
   * Sets feedback from login attempt.
   */
  setLoginFeedback(feedback: string): void {
    this.loginFeedback = feedback;
    this.hasError = true;
    this.loggingIn = false;
  }

  /**
   * Shows contact info for the help desk.
   */
  showHelpDeskInfo(): void {
    this.modalService.show(HelpDeskModalComponent);
  }

  /**
   * Check to see if the current UTC date and time, if exists, fall between
   * the start and end date in the persistent notification appSettings
   */
   checkNotificationDate(startDate: Date, endDate: Date): boolean {
    const dateFormatWithTime = 'MM/dd/yyyy hhmm';
    const dateFormat = 'MM/dd/yyyy';

    const today = DateTime.now().toUTC();
    let startDateUtc = DateTime.fromFormat(startDate.toString(), dateFormatWithTime).toUTC();

    if (!startDateUtc.isValid) {
      startDateUtc = DateTime.fromFormat(startDate.toString(), dateFormat).toUTC();
    }

    let endDateUtc = DateTime.fromFormat(endDate.toString(), dateFormatWithTime).toUTC();

    if (!endDateUtc.isValid) {
      endDateUtc = DateTime.fromFormat(endDate.toString(), dateFormat).toUTC();
    }

    return today >= startDateUtc && today <= endDateUtc ? true : false;
  }

  /**
   * Clears any data entered in the login form.
   */
  clearLoginFeedback(): void {
    this.loginFeedback = '';
    this.hasError = false;
    this.loggingIn = false;
    this.pkiLoginFeedback = '';
    this.hasPkiError = false;
    this.loggingInPki = false;
  }

  /**
   * Redirects the user to the appropriate page
   * based on the returnUrl.
   * If no returnUrl is present or invalid, the user is
   * redirected to the dashboard upon login.
   * If the returnUrl is valid, the user is
   * redirected to the return URL upon login.
   */
  redirectUsers(): void {
    const urlParams = new URLSearchParams(window.location.search);
    let retUrl = urlParams.get('returnUrl');
    const minigPath = Object.hasOwn(environment, 'minig') ? 'madss-app/' : '';

    if (retUrl !== null && retUrl !== '') {
      // decode the url
      retUrl = this.decodeUrl(retUrl);
      // from angular route - '/login;loginUrl=xxxx;returnUrl=xxx'
      // split on '=' and take last element
      // remove / start and end of retUrl
      // if that is null, then keep original string
      retUrl = retUrl.split('=')?.pop()?.replace(/^\/|\/$/g, '') ?? retUrl;
    }

    if (retUrl !== undefined && retUrl !== null && retUrl !== '' && retUrl !== '/') {
      window.location.href = `${window.location.origin}/${minigPath}${retUrl}`;
    }
    else {
      window.location.href = `${window.origin}/${minigPath}`;
    }
  }

  /**
   * Recursively decodes an encoded URL string to handle cases where the URL
   * string is encoded multiple times. Default max iterations is 20.
   *
   * @param str Encoded URL string.
   *
   * @param maxInterations Maximum number of iterations to decode.
   *
   * @param currentIteration Current iteration.
   *
   * @returns Decoded URL string.
   */
  decodeUrl(str: string, maxInterations = 20, currentIteration = 0): string {
    if (currentIteration >= maxInterations) {
        return str;
    }
    // %25 is the encoded value for %
    // %3D is the encoded value for =
    else if (typeof str === 'string' && (str.indexOf('%3D') !== -1 || str.indexOf('%25') !== -1)) {
        return this.decodeUrl(decodeURIComponent(str), maxInterations, currentIteration + 1);
    }

    return decodeURIComponent(str);
  }
}
