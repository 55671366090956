import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'src/app/models/app-settings';
import { AppSettingsService } from './../../services/app-settings.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent {
  public message: string;

  appSettings: AppSettings;

  enableMaintenanceMode?: boolean;
  maintenanceModeMessage?: string;

  constructor(
    private route: ActivatedRoute,
    private appSettingsService: AppSettingsService) {

    this.appSettings = this.appSettingsService.getAppSettings();
    this.enableMaintenanceMode = this.appSettings.enableMaintenanceMode;
    this.maintenanceModeMessage = this.appSettings.maintenanceModeMessage;

    if (route.snapshot.params.from) {
      this.message = 'You have successfully logged out of MADSS.';
    }
  }

  onDismissAlert(): void {
    this.message = '';
  }

}
