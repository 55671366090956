import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Login } from '../models/login';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private pkiHttpClient: HttpClient;
  constructor(private http: HttpClient, private backendHandler: HttpBackend) {
    this.pkiHttpClient = new HttpClient(backendHandler);
  }

  /**
   * Calls the service login endpoint passing the credentials from the
   * username and password fields in the login component.
   *
   * Passes an error handling to the handle error method.
   */
  loginUser(login: Login): Observable<any> {
    const requestUrl = 'external/login/basic';
    return this.http.post<any>(requestUrl,
     login,
     { withCredentials: true })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Makes a request to the route used for prompting for the client
   * certificate to support PKI authentication.
   *
   * Passes an error handling to the handle error method.
   */
  pkiLogin(): Observable<any> {
    const requestUrl = `https://auth-${window.location.hostname}/pki/login`;
    return this.pkiHttpClient.post<any>(
      requestUrl,
      null,
      { withCredentials: true })
      .pipe(
        catchError(this.handleError)
      );
  }

  /**
   * Prints out the error status code and message to the browser console.
   * This method needs to be more robust in the future allowing the
   * client application to better respond to the specific error message
   * received.
   *
   * @param error The error in the HTTP response received from the calls
   * to login.
   */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError('Login failed');
  }
}
