export const environment = {
  production: false,
  /**
   * The below URL is used to configure a different base URL for API requests
   * for different environments.
   *
   * This URL is prepended to any requests sent from the HttpClient.
   */
   apiUrl: '/api/',
   disableBasicLogin: false
};
