import { AfterViewInit, Component, ElementRef, Input, Renderer2, SecurityContext, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Component to display hyperlink using markdown styling with
 * the form [...](url)
 *   NOTE: Must include 'https://...' in the url
 * Example:
 *   [Link](https://github.com)
 */
@Component({
  selector: 'app-message-markdown',
  template: `
  <div #divContainer></div>
  `
})
export class MessageMarkdownComponent implements AfterViewInit {
  @Input() message = '';
  @Input() openUrlInSameWindow = false;

  @ViewChild('divContainer', { static: true }) divContainer: ElementRef;

  constructor(
    private renderer: Renderer2,
    private sanitizer: DomSanitizer) { }

  ngAfterViewInit() {
    const url =
      this.sanitizer
      .sanitize(SecurityContext.URL, this.message
        .replace(/\[([^[]+)\](\(([^)]*))\)/gim,
          `<a href="$3" ${ this.openUrlInSameWindow ? '' : 'target="_blank"' } rel="noreferrer">$1</a>`)) ?? '';

    // url has been sanitized by the DomSanitizer to remove rogue 'javascript://'
    // in the url.  It will prepend the 'unsafe' word if the url is outside
    // the MADSS domain
    this.renderer.setProperty(
      this.divContainer.nativeElement, 'outerHTML', url.replace('unsafe:', ''));
  }
}
