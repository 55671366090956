import { AppSettingsService } from './../../services/app-settings.service';
import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/models/app-settings';

@Component({
  selector: 'app-help-desk-modal',
  templateUrl: './help-desk-modal.component.html',
  styleUrls: ['./help-desk-modal.component.scss']
})
export class HelpDeskModalComponent {
  appSettings$: Observable<AppSettings>;

  /**
   * Constructor.
   */
  constructor(
    public appSettingsService: AppSettingsService,
    public modalRef: BsModalRef) {

    this.appSettings$ = this.appSettingsService.getAppSettingsObservable();
  }

  /**
   * Closes the modal in response to the user clicking a close command.
   */
  onCloseClicked(): void {
    this.modalRef.hide();
  }
}
