<nav class="navbar navbar-expand-md bg-dark shadow">
  <a class="navbar-brand ms-2" routerLink="./">
    <img src="assets/images/madss-logo.png" alt="MADSS" width="30" height="30">
  </a>

  <button class="navbar-toggler" type="button" (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" [ngClass]="{'show': navbarOpen}" id="mainNavBar">
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <a class="nav-link" routerLink="/welcome" routerLinkActive="active">Welcome</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" routerLink="/requestaccess" routerLinkActive="active">Request Access</a>
      </li>
    </ul>
  </div>
</nav>
