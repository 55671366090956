<div class="flex-root">
  <div class="col-4 h-100 top-margin">
    <div class="row logout-box shadowed">
      <div class="d-flex justify-content-center">
        <div class="mb-5 mt-5 pl-1 pr-1">
          <h4 class="text-center pb-2">You have successfully logged out</h4>
          <button (click)="(login())" class="btn btn-primary btn-large btn-block mt-2 w-100" autofocus>
            Click to log back in
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
